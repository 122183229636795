import React from 'react'

const  AppContext = React.createContext("applicationData");

const appSettings = {
    displayYear: 2021
}

export { appSettings };



export default AppContext