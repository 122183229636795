import React from "react";
import styled from "styled-components";

const StyledFooter = styled.footer`
  border-top: 2px solid #000;

  @media (min-width: 800px) {
    max-height: 220px;
    padding: 0px 0 30px 10px;
  }

  @media (max-width: 750px) {
    padding: 10px;
    line-height: 10px;
    position: fixed;
    bottom: 0;
    width: 99vw;
    background: #fff;

    a {
      display: flex;
    }
  }

  @media (min-width: 1305px) {
    padding-left: 40px;
  }
`;

const Footer = ({ className, store }) => {
  /*
  if (!store.SessionStore.authUser)
    return null
*/
  const year = new Date().getFullYear();
  return (
    <StyledFooter>
      <br />
      Komplott 1999 - {year} ©
      <br />
      <br />
      <a href="tel:46-737542200">+46 737542200</a>
      <br />
      <a href="mailto:office@komplott.com">office@komplott.com</a>
    </StyledFooter>
  );
};

export { Footer };

export default Footer;
