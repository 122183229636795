import React from 'react';
import StyledTracklist from './style'

const Tracks = (props) => {
    const {data: tracks} = props
    return (
        <StyledTracklist {...props}>
            {tracks && tracks.map((track, index) => <li key={index}>
                { track.artist && <strong>{track.artist}&nbsp;</strong> }
                {`${track.title} ${track.time
                    ? track.time
                    : ''}`}
            </li>)}
        </StyledTracklist>
    );
}

const Tracklist = (props) => {
    const {data: tracklist} = props
    const isVinyl = (tracklist && tracklist.A) || (tracklist && tracklist.B)

    return (
        <div style={{position:'relative'}}>
            <strong>Tracklist</strong>
            {tracklist && tracklist.A && <div>
                <strong>A</strong>
                <Tracks data={tracklist.A}/>
            </div>}
            {tracklist && tracklist.B && <div>
                <strong>B</strong>
                <Tracks data={tracklist.B}/>
            </div>}

            {!isVinyl && <div>
                <Tracks data={tracklist}  />
            </div>}

            <br/>
        </div>
    );
}

export default Tracklist;