import React, { Component } from "react";
import Page from "../../components.base/Page";

class PageStarfieldSimulation extends Component {
  render() {
    return (
      <Page className="page-starfieldsimulation" title="Komplott">
        <h1>Starfield Simulation</h1>
        <br/>
        <br />
      </Page>
    );
  }
}

export default PageStarfieldSimulation;
