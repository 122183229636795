import React from "react";
import Page from "../../components.base/Page";
import Navigation from "../../components.common/Navigation";
//import Catalogue from "../../components.common/Catalogue";
import CatalogueList from "../../components.common/Catalogue/List";

const PageIndex = () => {
  //const [displayList, setListDisplay] = useState(true);

  return (
    <Page className="page-home" title="Komplott">
      <Navigation />
      {/*displayList ? <CatalogueList /> : <Catalogue />*/}
      <CatalogueList />
    </Page>
  );
};

export default PageIndex;
