export const LANDING = '/';
export const CATALOGUE = '/catalogue';
export const ARCHIVE = '/archive';

const authRoutes = [
  {
    url: LANDING,
    label: 'Catalogue'
  }
];

const nonAuthRoutes = [
  {
    url: CATALOGUE,
    label: 'Catalogue'
  },
  {
    url: ARCHIVE,
    label: 'Archive'
  }
];

export {authRoutes};

export {nonAuthRoutes};
