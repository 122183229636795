export const color_1 = `#758DAD`;
export const color_2 = `#fbfef9`;
export const color_3 = `#FECF51`;
export const color_4 = `#A5797F`;
export const color_5 = `#000004`;
export const color_6 = `#999999`;
export const color_7 = `#212121`;
export const color_8 = `#FFF`;
//export const color_8 = `#212121`;

export const winnerColor = `green`;
export const loserColor = `#EE2E38`;
export const drawColor = `yellow`;

export const font_family_1 = ` 'Tajawal', sans-serif `;
