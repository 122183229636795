import { useState, useEffect } from "react";

function useMobileViewportCheck() {
  let MOBILE_BREAKPOINT =
    (window && window.matchMedia && window.matchMedia("(max-width: 750px)")) ||
    false;
  const [mobileViewport, setViewport] = useState(MOBILE_BREAKPOINT.matches);

  useEffect(() => {
    const handleResize = () => setViewport(MOBILE_BREAKPOINT.matches);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  return mobileViewport;
}

export default useMobileViewportCheck;
