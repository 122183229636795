import styled from "styled-components";

const StyledCatalogueItem = styled.div`
  display: flex;
  flex-wrap: wrap;

  .column {
    width: auto;
    padding: 10px;
    font-size: 1rem;
    max-width: 50vw;
  }

  h2 {
    margin-bottom: 0px;
  }

  @media only screen and (min-width: 800px) {
    .column {
      font-size: 1rem;
      max-width: 80vw;
      line-height: 1.2rem;
    }

    div.column:first-of-type {
      min-width: 40vw;
      text-align: center;
    }
  }

  @media only screen and (max-width: 800px) {
    .column {
      font-size: 1rem;
      max-width: 80vw;
      line-height: 1;
    }
  }
`;

export default StyledCatalogueItem;
