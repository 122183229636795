import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Logo = ({ className, ...props }) => {
  return (
    <img
      alt={props.alt}
      className={`logo ${className}`}
      src={props.src}
      {...props}
    />
  );
};

Logo.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
};

Logo.defaultProps = {
  alt: "",
  src: ""
};

export default styled(Logo)`
  width: 15vw;
  height: 15vw;
  margin: 5px;
  max-width: 150px;
`;
