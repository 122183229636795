import React, {useState} from "react";
import { useSpring, animated } from "react-spring";
import Logotype from "../../logo4.svg";
import Logotype1 from "../../logo.svg";
import Logotype2 from "../../logo2.svg";
import Logotype3 from "../../logo3.svg";
import Logotype4 from "../../logo5.svg";
import Logo from "../../components.base/Logo";
import * as theme from "../../style";
import styled from "styled-components";

const LOGOS = [Logotype, Logotype1, Logotype2, Logotype3, Logotype4]
const SELECTED_LOGO = LOGOS[ Math.floor(Math.random() * Math.floor(LOGOS.length)) ]

const StyledSplash = styled.div`
  background: ${theme.color_2};
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const Splash = ({ store }, props) => {
  const animationProps = useSpring({
    to: { opacity: 1, transform: "scale(3)" },
    from: { opacity: 0, transform: "scale(10)" },
  });

  const [displaySplash, setSplash] = useState(true)

  window.setTimeout(() => setSplash(false), 2000)

  return displaySplash ? (
    <StyledSplash>
      <animated.div style={animationProps}>
        <Logo src={SELECTED_LOGO} alt="Komplott" className="loader" />
      </animated.div>
    </StyledSplash>
  ) : null;
};

export default Splash;
