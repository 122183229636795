import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Header from "../Header";
import Footer from "../Footer";
import Splash from "../Splash";
//import Loader from "../Loader";
import Index from "../../components.pages/Index";
import Catalogue from "../../components.pages/Catalogue";
import Contact from "../../components.pages/Contact";
import StarfieldSimulation from "../../components.pages/StarfieldSimulation";
import Page_404 from "../../components.pages/404";
import CatalogueItem from "../../components.pages/Catalogue/Item/";
import Image from "../../components.pages/Image";

// import * as routes from '../../routes'; import styled from
// 'styled-components';

const App = (props) => (
  <BrowserRouter>
    <React.Fragment>
      <Header />
      <Splash />
      {/*<Loader />*/}
      <Switch>
        <Route exact path="/" component={Index} />
        <Route exact path="/catalogue" component={Catalogue} />
        <Route exact path="/catalogue/:title" component={CatalogueItem} />
        <Route exact path="/archive" component={Image} />
        <Route exact path="/image" component={Image} />
        <Route exact path="/image/:title" component={Image} />
        {/*<Route exact path="/video" component={Video} />
        <Route exact path="/video/:title" component={Video} />*/}
        <Route exact path="/contact" component={Contact} />
        <Route exact path="/kontakt" component={Contact} />
        <Route
          exact
          path="/starfield-simulation"
          component={StarfieldSimulation}
        />
        <Route
          exact
          path="/starfieldsimulation"
          component={StarfieldSimulation}
        />
        <Route component={Page_404} />
      </Switch>
      <Footer />
    </React.Fragment>
  </BrowserRouter>
);

export default App;
