import styled from "styled-components";
import * as theme from "../../../style";

const StyledCatalogueList = styled.div `
  .selected {
    background: ${theme.color_4};
    color: ${theme.color_2};
    padding: 10px 10px 5px 10px;
  }

  h2 {
    margin-top: 10px;
  }

  ul {
    list-style: none;
    margin: 0;
  }

  li {
    font-size: 1rem;
    margin-right: 15px;
    overflow: hidden;
    margin: 0;
    padding: 0;
    padding-left: 10px;
    background: #555;
    color: #fff;
    margin: 1px 0;
  }

  li:hover {
    background: #000;
  }

  a {
    position: relative;
    overflow: hidden;
    color: ${theme.color_8};
    -webkit-text-fill-color: ${theme.color_8};
    text-fill-color: ${theme.color_8};
    text-decoration: none;
    transition: all .35s ease-in;
  }
 
  a span {
    -webkit-text-fill-color: ${theme.color_8};
    text-fill-color: ${theme.color_8};
  }

  a:hover {
    text-decoration: underline;
    text-fill-color: #000004;
    -webkit-text-fill-color: #000004;
  }

  @media (max-width: 800px) {
    li:not(:first-of-type) {
      margin: 20px 0;
      max-width: 90vw;
      line-height: 25px;
    }

    li {
      padding: 5px;
      padding-left: 10px;
      line-height: 20px;
    }

  }


`;

export default StyledCatalogueList;
