import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { compose } from "recompose";
import Page from "../../../components.base/Page";
import CatalogueItem from "../../../components.common/Catalogue/Item";
import CataloguePageNav from "../../../components.common/Catalogue/PageNav";
import AppContext from "../../../AppContext";
import {
  getCatalogueItem,
  getNextCatalogueItem,
  getPreviousCatalogueItem,
  getRandomCatalogueItem,
  firstEntry,
  lastEntry,
} from "../../../json/catalogue";
import useViewport from "../../../effects/useViewport";

const PageCatalogueItem = (props) => {
  const animationProps = useSpring({
    to: { opacity: 1, transform: "scale(1)" },
    from: { opacity: 1, transform: "scale(0)" },
  });
  const [item, setItem] = useState(getCatalogueItem(props.match.params.title));
  const [ID, setID] = useState(item.ID || 1);
  const AppData = useContext(AppContext);
  const mobileViewport = useViewport();
  const isFirstInCatalogue = firstEntry(item.ID) ? true : false;
  const isLastInCatalogue = lastEntry(item.ID) ? true : false;

  const nextItem = () => {
    let next = getNextCatalogueItem(ID);
    props.history.push(`/catalogue/${next.url}`);
    setID(next.ID);
    setItem(next);
    scrollTop();
  };

  const previousItem = () => {
    let previous = getPreviousCatalogueItem(ID);
    props.history.push(`/catalogue/${previous.url}`);
    setID(previous.ID);
    setItem(previous);
    scrollTop();
  };

  const randomItem = () => {
    let random = getRandomCatalogueItem();
    props.history.push(`/catalogue/${random.url}`);
    setID(random.ID);
    setItem(random);
    scrollTop();
  };

  if (!item) {
    return null;
  }

  const pageTitle = `${item.title} - ${item.artist} || Komplott `;
  AppData.displayYear = parseInt(item.year);

  return (
    <Page className="page-home" title={pageTitle}>
      <animated.div style={animationProps}>
        {!mobileViewport && (
          <CataloguePageNav
            next={nextItem}
            previous={previousItem}
            random={randomItem}
            first={isFirstInCatalogue}
            last={isLastInCatalogue}
          />
        )}
        <CatalogueItem item={item} />
        <hr />
        <CataloguePageNav
          next={nextItem}
          previous={previousItem}
          random={randomItem}
          first={isFirstInCatalogue}
          last={isLastInCatalogue}
        />
      </animated.div>
    </Page>
  );
};

export default compose(withRouter)(PageCatalogueItem);

function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
