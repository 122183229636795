import React, { useContext } from "react";
import { Link } from "react-router-dom";
import Logo from "../../components.base/Logo";
import styled from "styled-components";
import Logotype from "../../logo4.svg";
import Logotype1 from "../../logo.svg";
import Logotype2 from "../../logo2.svg";
import Logotype3 from "../../logo3.svg";
import Logotype4 from "../../logo5.svg";
import AppContext from "../../AppContext";

const StyledHeader = styled.header`
  border-bottom: 2px solid #000;

  @media (min-width: 800px) {
    max-height: 220px;
    padding-left: 0 !important;
    width: calc(100vw - 26px);
    position: fixed;
    background: #fff;
    z-index: 1;
    top: 0;
    padding-top: 10px;
  }

  @media (max-width: 750px) {
    position: fixed;
    z-index: 1;
    background: #fff;
    width: 99vw;
    top: 0;
    padding-left: 20px;
    box-sizing: border-box;

    a {
      display: flex;
    }

    img {
      width: 20vw;
      height: 20vw;
      margin-bottom: 10px;
    }
  }

  @media (min-width: 1305px) {
    padding-left: 40px;
  }
`;

const Header = ({ className, store }) => {
  const AppData = useContext(AppContext);
  let currentLogo = Logotype;

  if (AppData.displayYear > 2009 && AppData.displayYear < 2016) {
    currentLogo = Logotype1;
  }

  if (AppData.displayYear > 2007 && AppData.displayYear < 2010) {
    currentLogo = Logotype4;
  }

  if (AppData.displayYear > 2004 && AppData.displayYear < 2008) {
    currentLogo = Logotype2;
  }

  if (AppData.displayYear < 2004) {
    currentLogo = Logotype3;
  }

  return (
    <StyledHeader>
      <Link to="/">
        <Logo src={currentLogo} alt="Komplott" />
      </Link>
    </StyledHeader>
  );
};

export { Header };

export default Header;
