import React from "react";
import FadeIn from "react-fade-in";
import Page from "../../components.base/Page";
//TODO extract json cat from component
import catalogue from "../../json/catalogue";
import CatalogueItem from "../../components.common/Catalogue/Item";
import StyledCatalogue from "./style";

const sortedCatalogue = catalogue.sort((a, b) => (a.year < b.year ? 1 : -1));

const Catalogue = (props) => {
  return (
    <>
      <FadeIn>
        <StyledCatalogue>
          {sortedCatalogue.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Page>
                  <CatalogueItem item={item} />
                </Page>
              </React.Fragment>
            );
          })}
        </StyledCatalogue>
      </FadeIn>
    </>
  );
};

export default Catalogue;
