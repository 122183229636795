import React, { Component } from "react";
import Page from "../../components.base/Page";

class PageContact extends Component {
  render() {
    return (
      <Page className="page-home" title="Komplott">
        <h1>Contact</h1>
        <br/>
        <br/>
        <strong><label>Call:</label></strong>
        <a href="tel:46-737542200">+46 737542200</a>
        <br/>
        <br/>
        <strong><label>Mail:</label></strong>
        <a href="mailto:office@komplott.com">office@komplott.com</a>
        <br />
        <br />
      </Page>
    );
  }
}

export default PageContact;
