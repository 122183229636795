import React from "react";
import styled from "styled-components";

const StyledCover = styled.div`

    img {
        box-shadow: 1px 1px 7px 1px #ccc;
    }

    @media only screen and (min-width: 700px) {
        img {
            height: 330px
            max-height: 330px;
        }
    }

    @media only screen and (max-width: 700px) {
        width: 93vw;
        
        img {
            width: 95vw;
            max-width: 100%;
            min-width: 85vw;
            min-height: 200px;
        }
    }
`;

const CatalogueCover = (props) => {
  return (
    <StyledCover>
      <img src={props.src} loading="lazy" alt={props.title} />
    </StyledCover>
  );
};

export default CatalogueCover;
