import React, { Component } from "react";
import {Link} from 'react-router-dom';
import Page from "../../components.base/Page";

class PageContact extends Component {
  render() {
    return (
      <Page className="page-home" title="Komplott">
        <h1>Sorry – the page you have requested does not exist</h1>
        <p>You may have followed an outdated link, or have mistyped a URL.</p>
        <br/>
        <Link to="/" style={{textDecoration:'underline'}}>Go to </Link> the Komplott catalogue page
        <br/>
        
      </Page>
    );
  }
}

export default PageContact;
