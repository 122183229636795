import React from 'react';
import ReactDOM from 'react-dom';
import App from './components.base/App';
import registerServiceWorker from './registerServiceWorker';
import './index.css';
import AppContext, {appSettings} from "./AppContext"

ReactDOM.render(    
    <AppContext.Provider value={appSettings}>
        <App/>
    </AppContext.Provider>, document.getElementById('root'));
registerServiceWorker();
