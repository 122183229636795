import React from "react";
import List, { ListItem } from "../../components.base/List";
import { Link, withRouter } from "react-router-dom";
import { nonAuthRoutes } from "../../routes";
import StyledNavigationList from "./style";
import {} from 'react-router-dom';

const Navigation = (props) => {
  let {pathname} = props.location

  return <StyledNavigationList>
    <List>
      {nonAuthRoutes.map((route, index) => 
         route.url !== pathname && <ListItem key={index}>
          <Link to={route.url}>{route.label}</Link>
        </ListItem>
      )}
    </List>
  </StyledNavigationList>
};

export default withRouter(Navigation);
