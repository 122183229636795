import React, { useState } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { useSpring, animated } from "react-spring";
import { compose } from "recompose";
import Page from "../../components.base/Page";
import images from "../../json/images";
import CataloguePageNav from "../../components.common/Catalogue/PageNav";
import {
  getImage,
  getNextImage,
  getPreviousImage,
  getRandomImage,
  firstEntry,
  lastEntry,
} from "../../functions";

const StyledArhiveImage = styled.div`
  img {
    max-width: 70vw;
    min-width: 70vw;
    object-fit: scale-down;
    filter: grayscale(1);
    display: flex;
    margin: 5vw auto 0 auto;
    cursor: pointer;
    transition: filter 4s cubic-bezier(0.42, 0, 0.82, 0.14);
    max-height: 55vh;
    min-height: 55vh;
  }

  img.fullscreen {
    width: 100vw;
    max-width: 100vw;
    min-width: 100vw;
    filter: grayscale(0);
    top: 0;
    left: 0;
    object-fit: contain;
    max-height: unset;
    z-index: 2;
    margin: 0;
  }

  i {
    padding: 0 2vw;
    max-width: 90vw;
    display: flex;
    line-height: 3vh;
  }

  @media only screen and (max-width: 720px) {
    img {
      max-width: 100vw;
      min-width: 70vw;
      margin: 0 auto;
    }
  }
`;

const PageImage = (props) => {
  const animationProps = useSpring({
    to: { opacity: 1, transform: "scale(1)" },
    from: { opacity: 1, transform: "scale(1)" },
  });
  const { title } = props.match.params;
  const [image, setImage] = useState(
    title
      ? getImage(images, props.match.params.title)
      : getRandomImage(images, props.match.params.title),
  );
  const [ID, setID] = useState(image.ID || 1);
  const isFirstInCatalogue = firstEntry(images, image.ID) ? true : false;
  const isLastInCatalogue = lastEntry(images, image.ID) ? true : false;

  const nextItem = () => {
    let next = getNextImage(images, ID);
    props.history.push(`/image/${next.title}`);
    setID(next.ID);
    setImage(next);
    scrollTop();
  };

  const previousItem = () => {
    let previous = getPreviousImage(images, ID);
    props.history.push(`/image/${previous.title}`);
    setID(previous.ID);
    setImage(previous);
    scrollTop();
  };

  const randomItem = () => {
    let random = getRandomImage(images);
    props.history.push(`/image/${random.title}`);
    setID(random.ID);
    setImage(random);
    scrollTop();
  };

  if (!image) {
    return null;
  }

  const pageTitle = `${image.title} - ${image.year} | Komplott `;

  return (
    <Page className="page-home" title={pageTitle}>
      <animated.div style={animationProps}>
        <ArchiveImage image={image} />
        <hr />
        <CataloguePageNav
          next={nextItem}
          previous={previousItem}
          random={randomItem}
          first={isFirstInCatalogue}
          last={isLastInCatalogue}
        />
      </animated.div>
    </Page>
  );
};

const ArchiveImage = ({ image }) => {
  const [fullscreen, setFullscreen] = useState(false);

  return (
    <StyledArhiveImage>
      <img
        className={`${fullscreen ? "fullscreen" : ""}`}
        src={`/img/archive/${image.src}`}
        alt={`${image.description}`}
        loading="lazy"
        onClick={() => setFullscreen(!fullscreen)}
      />
      <br />
      {image.description && <i>{image.description}</i>}
    </StyledArhiveImage>
  );
};

export default compose(withRouter)(PageImage);
function scrollTop() {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  });
}
