import styled from 'styled-components'

const StyledCatalogue = styled.div`
    display:flex;
    flex-wrap:wrap;
    margin-top: 70px;
    border-bottom: 1px solid #000;
    padding-bottom: 3vh;
    
    .page {
        width: 100vw;
        min-height: 70vh;

    }
`

export default StyledCatalogue