import React from "react";
import CatalogueCover from "../../../components.common/Catalogue/Cover";
import Tracklist from "../../../components.common/Catalogue/Item/Tracklist";
import Formats from "../../../components.common/Catalogue/Item/Formats";
import Streams from "../../../components.common/Catalogue/Item/Stream";
import Download from "../../../components.common/Catalogue/Item/Downloads";
import CatalogueText from "../../../components.common/Catalogue/Text";
//import OrderButton from '../../../components.common/Order/Button';
import StyledCatalogueItem from "./style";
import StyledLink  from '../../StyledLink';

const CatalogueItemLabel = props => {
  return (
    <>
      <strong>{props.label}</strong>
      &nbsp;
      {props.value}
    </>
  );
};

const CatalogueItem = props => {
  if(!props.item) {return null}
  
  const {
    title,
    artist,
    catNr,
    year,
    format,
    date,
    cover,
    tracklist,
    streaming,
    download,
    discogs,
    ean,
  } = props.item;

  return (
    <StyledCatalogueItem>
      {cover && cover !== "" &&
      <div className="column">
        <CatalogueCover src={`/img/covers/${cover}`} />
      </div>
      }
      <div className="column">
        <h2>{artist}</h2>
        <h3>{title}</h3>
        <CatalogueItemLabel label="Cataloguenumber" value={catNr} />
        <br />
        <CatalogueItemLabel label="Year" value={year} />
        <br />
        <CatalogueItemLabel label="Realasedate" value={date} />
        <br />
        <Formats formats={format} />
        {ean && <CatalogueItemLabel label="Ean" value={ean} />}
        <br />
        <Tracklist data={tracklist} />
        {download && <Download download={download} />}
        {streaming && streaming.length > 0 && <Streams streams={streaming} />}
        {discogs && (
          <>
            <strong>Discogs</strong>
            &nbsp;
            <StyledLink href={discogs} target="_blank">Link</StyledLink>
          </>
        )}
      </div>

      <CatalogueText />
    </StyledCatalogueItem>
  );
};

export default CatalogueItem;
