import React from "react";
import StyledLink from "../../../StyledLink";

const BASE_PATH =
  "https://firebasestorage.googleapis.com/v0/b/komplott-f1ba3.appspot.com/o/";

const Download = props => {
  const { download } = props;

  return (
    <div>
      <StyledLink href={`${BASE_PATH}${download.link}?alt=media`} target="_blank" download>
        Download
      </StyledLink>
    </div>
  );
};

export default Download;
