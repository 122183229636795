import React, {Component} from 'react';

class ListItem extends Component {
  render() {
    return <li className="">{this.props.children}</li>;
  }
}

ListItem.defaultProps = {
  name: ''
};

export default ListItem;
