import styled from "styled-components";

const StyledTracklist = styled.ol`
  margin: 3px 0px 10px 12px;
  padding: 0 20px 0 35px;

  @media only screen and (min-width: 700px) {
    max-width: 45vw;
  }
`;

export default StyledTracklist;
