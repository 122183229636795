export function getImage(items, title) {
  for (var i = 0; i < items.length; i++) {
    if (items[i].title === title) return items[i];
  }
}

export function getNextImage(items, ID) {
  for (var i = 0; i < items.length; i++) {
    if (items[i].ID === ID) {
      let endOfitems = i + 1 > items.length;
      return endOfitems ? items[0] : items[i + 1];
    }
  }
}

export function getPreviousImage(items, ID) {
  for (var i = 0; i < items.length; i++) {
    if (items[i].ID === ID) {
      let startOfitems = i - 1 <= 0;

      return startOfitems ? items[items.length - 1] : items[i - 1];
    }
  }
}

export function getRandomImage(items) {
  return items[Math.floor(Math.random() * items.length)];
}

export function firstEntry(items, ID) {
  return items[0].ID === ID;
}

export function lastEntry(items, ID) {
  return items[items.length - 1].ID === ID;
}
