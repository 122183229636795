import React from "react";
import StyledCataloguePageNav from "./style";

const PageNav = (props) => {
  const { previous, next, random, first, last } = props;
  return (
    <StyledCataloguePageNav>
      <button
        onClick={() => previous()}
        style={{ opacity: `${first ? 0 : 1}` }}
      >
        {" "}
        {`< Previous`}
      </button>
      <button onClick={() => random()}>? Random</button>
      <button onClick={() => next()} style={{ opacity: `${last ? 0 : 1}` }}>
        Next >{" "}
      </button>
    </StyledCataloguePageNav>
  );
};

export default PageNav;
