import React from "react";
import StyledLink from "../../../StyledLink";
import styled from "styled-components"

const StyledSteam = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const Streams = props => {
  const { streams } = props;

  return (
    <StyledSteam>
      <strong>Stream</strong>
      &nbsp;
      {streams.map( (stream,index) => {
        const displayLink = stream.http_url !== "" && stream.name !== "";
        
        return displayLink ? (
          <StyledLink key={index} href={`${stream.http_url}`} target="_blank">
            {stream.name}
          </StyledLink>
        ) : null;
      })}
    </StyledSteam>
  );
};

export default Streams;
