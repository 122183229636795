import React from "react";
import { Link } from "react-router-dom";
import FadeIn from "react-fade-in";
import List, { ListItem } from "../../../components.base/List";
//TODO extract json cat from component
import catalogue from "../../../json/catalogue";
import StyledCatalogueList from "./style";
import useViewport from "../../../effects/useViewport";

const sortedCatalogue = catalogue.sort((a, b) => (a.year < b.year ? 1 : -1));

const CatalogueList = (props) => {
  const mobileViewport = useViewport();

  return (
    <StyledCatalogueList>
      <List>
        <FadeIn>
          {sortedCatalogue.map((item, index) => {
            let displayYear =
              index !== 0 && item.year !== sortedCatalogue[index - 1].year;

            return (
              <React.Fragment key={index}>
                {displayYear && <br />}
                <Link to={`catalogue/${item.url}`}>
                  <ListItem key={displayYear ? null : index}>
                    {!mobileViewport ? (
                      <>
                        <span>
                          {item.artist !== item.title && item.artist + " - "}
                        </span>{" "}
                        <span>{item.title}</span>
                      </>
                    ) : (
                      <>
                        <div>
                          <strong>
                            {item.artist !== item.title && item.artist}
                          </strong>
                        </div>
                        <div>{item.title}</div>
                      </>
                    )}
                  </ListItem>
                </Link>
              </React.Fragment>
            );
          })}
        </FadeIn>
      </List>
    </StyledCatalogueList>
  );
};

export default CatalogueList;
