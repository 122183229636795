import React, {Component} from 'react';
import Markdown from 'react-markdown';
//import fs from 'fs';

class CatalogueText extends Component {

    constructor(props) {
        super(props);

        this.state = {
            text: ''
        }
    }

    componentDidMount() {
        let text = "" //fs.readFileSync("../../../doc/press/sjokort-fem-oar.md", 'utf8')

        this.setState = ({text: text})

    }
    render() {

        return (<Markdown escapeHtml={true} source={this.statetext}/>);
    }
}

export default CatalogueText;