const images = [
  {
    description: "",
    title: "All Spec Kit, new album initiated",
    date: "2016-03-31",
    year: "2016",
    artist: ["All spec kit"],
    thumbnail: "All_Spec_Kit_-_Newalbum_initiated.jpg",
    src: "All_Spec_Kit_-_Newalbum_initiated.jpg",
  },
  {
    description: "",
    title: "All Spec Kit, live at Folkhemmet",
    date: "2016-03-31",
    year: "2016",
    artist: ["All spec kit"],
    thumbnail: "All_Spec_Kit_liveFolkhemmet.jpg",
    src: "All_Spec_Kit_liveFolkhemmet.jpg",
  },
  {
    title: "06",
    description: "",
    src: "06.jpg",
    size: 118490,
    date: "",
    year: "",
    artist: ["All Spec Kit"],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:26.492Z",
  },
  {
    title: "118_1878",
    description: "",
    src: "118_1878.jpg",
    size: 408272,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:51:36.823Z",
  },
  {
    title: "118_1850",
    description: "Sonar 2004",
    src: "118_1850.jpg",
    size: 281254,
    date: "",
    year: "",
    artist: ["No Source One"],
    thumbnail: "",
    uploaded: "2021-03-15T21:51:06.189Z",
  },
  {
    title: "119_1924",
    description: "Sonar 2004",
    src: "119_1924.jpg",
    size: 471101,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:51:51.265Z",
  },
  {
    title: "120_2034",
    description: "Sonar 2004",
    src: "120_2034.jpg",
    size: 378427,
    date: "",
    year: "",
    artist: ["Son of clay"],
    thumbnail: "",
    uploaded: "2021-03-15T21:52:13.352Z",
  },
  {
    title: "120_2065",
    description: "Son of clay Sonar 2004 live set",
    src: "120_2065.jpg",
    size: 232288,
    date: "",
    year: "",
    artist: ["Son of clay"],
    thumbnail: "",
    uploaded: "2021-03-15T21:52:49.021Z",
  },
  {
    title: "124_2464",
    description: "",
    src: "124_2464.jpg",
    size: 416104,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:53:01.528Z",
  },
  {
    title: "125_2556",
    description:
      "Hans Appelqvist, Frans Gillberg. Tempo, Södra Skolgatan 30 Malmö 2004",
    src: "125_2556.jpg",
    size: 363796,
    date: "",
    year: "2004",
    artist: ["Hans Appelqvist", "No source one"],
    thumbnail: "",
    uploaded: "2021-03-15T21:53:26.229Z",
  },
  {
    title: "20",
    description: "",
    src: "20.jpg",
    size: 122448,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:12.319Z",
  },
  {
    title: "allspeckit_01",
    description: "",
    src: "allspeckit_01.jpg",
    size: 74982,
    date: "",
    year: "",
    artist: ["All Spec Kit"],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.852Z",
  },

  {
    title: "andlig tabell",
    description: "All Spec Kit, andlig tabell",
    src: "andlig tabell.jpg",
    size: 107669,
    date: "",
    year: "",
    artist: ["All Spec Kit"],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.873Z",
  },
  {
    title: "clay_and_bodin",
    description: "",
    src: "clay_and_bodin.jpg",
    size: 69376,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.895Z",
  },
  {
    title: "clay_and_no_source",
    description:
      "Son of Clay and No source one,  live set at Starfield simulation, Scaniaparken Malmö",
    src: "clay_and_no_source.jpg",
    size: 120757,
    date: "",
    year: "",
    artist: ["Son of clay", "No source one"],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.911Z",
  },
  {
    title: "hansappelqvist_2",
    description: "Hans Appelqvist, press image Bremort ",
    src: "hansappelqvist_2.jpg",
    size: 7245,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:42:26.330Z",
  },
  {
    title: "Hans Appelqvist Foto_ Micke GrönbergSR.jpg",
    description: "Hans Appelqvist (Foto_ Micke Grönberg SR)",
    src: "Hans Appelqvist Foto_ Micke GrönbergSR.jpg.jpg",
    size: 36736,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:43:49.413Z",
  },
  {
    title: "Komplott_Sydsvenskan",
    description: "",
    src: "Komplott_Sydsvenskan.jpg",
    size: 148764,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.940Z",
  },
  {
    title: "malmo hazard",
    description: "Starfield Simulation, scaniaparken Malmö. Hazard live set",
    src: "malmo hazard.jpg",
    size: 127891,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.521Z",
  },
  {
    title: "nyflajer",
    description: "",
    src: "nyflajer.jpg",
    size: 94316,
    date: "",
    year: "",
    artist: ["Ronnie Sundin"],
    thumbnail: "",
    uploaded: "2021-03-15T21:41:21.214Z",
  },
  {
    title: "PICT1737",
    description: "",
    src: "PICT1737.jpg",
    size: 162007,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:53:40.310Z",
  },
  {
    title: "PICT2174",
    description: "",
    src: "PICT2174.jpg",
    size: 117519,
    date: "",
    year: "",
    artist: ["Son of clay"],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:03.038Z",
  },
  {
    title: "PICT2180",
    description: "",
    src: "PICT2180.jpg",
    size: 131552,
    date: "",
    year: "",
    artist: ["Son of clay", "Hans Appelqvist", "Figurera"],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:49.428Z",
  },
  {
    title: "PICT2182",
    description: "",
    src: "PICT2182.jpg",
    size: 94631,
    date: "",
    year: "",
    artist: ["Son of clay", "Figurera"],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:03.052Z",
  },
  {
    title: "PICT2197",
    description: "",
    src: "PICT2197.jpg",
    size: 81081,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:50:03.025Z",
  },
  {
    title: "Ronnie_Sundin_KRETS-091222",
    description: "Ronnie Sundin at KRETS Gallery, december 2009",
    src: "Ronnie_Sundin_KRETS-091222.jpg",
    size: 1981021,
    date: "2009-12-22",
    year: "2009",
    artist: ["Ronnie_Sundin"],
    thumbnail: "",
    uploaded: "2021-03-15T21:44:10.411Z",
  },
  {
    title: "scaniaparken 200408 01",
    description: "Starfield Simulation Scaniaparken August 2004",
    src: "scaniaparken 200408 01.jpg",
    size: 121468,
    date: "",
    year: "2004",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.552Z",
  },
  {
    title: "scaniaparken 200408 02",
    description: "Starfield simulation Scaniaparken August 2004",
    src: "scaniaparken 200408 02.jpg",
    size: 145547,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.568Z",
  },
  {
    title: "scaniaparken 200408 03",
    description: "Starfield simulation Scaniaparken August 2004",
    src: "scaniaparken 200408 03.jpg",
    size: 82063,
    date: "2004-08-xx",
    year: "2004",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.583Z",
  },
  {
    title: "Son-of-Clay_-_Lab",
    description: "Son of Clay, live set Copenhagen",
    src: "Son-of-Clay_-_Lab.jpg",
    size: 75888,
    date: "",
    year: "",
    artist: ["Son of clay"],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.598Z",
  },
  {
    title: "Son-of-Clay_-_Rooseum",
    description: "Son of Clay, live set Rooseum Malmö",
    src: "Son-of-Clay_-_Rooseum.jpg",
    size: 38344,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.611Z",
  },
  {
    title: "sonofclay_05",
    description: "",
    src: "sonofclay_05.jpg",
    size: 73100,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-15T21:42:23.204Z",
  },
  {
    title: "Starfield_Simulation_1",
    description: "Flyer Starfield Simulation 1, Rooseum March 2001",
    src: "Starfield_Simulation_1.jpg",
    size: 38495,
    date: "2001-03-24",
    year: "2001",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.626Z",
  },
  {
    title: "Starfield_Simulation_14",
    description: "",
    src: "Starfield_Simulation_14.jpg",
    size: 38285,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.671Z",
  },
  {
    title: "Starfield_Simulation_15",
    description: "",
    src: "Starfield_Simulation_15.jpg",
    size: 46098,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.685Z",
  },
  {
    title: "Starfield_Simulation_2",
    description: "",
    src: "Starfield_Simulation_2.jpg",
    size: 15901,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.640Z",
  },
  {
    title: "Starfield_Simulation_28",
    description: "Starfield Simulation #28",
    src: "Starfield_Simulation_28.jpg",
    size: 59044,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.700Z",
  },
  {
    title: "Starfield_Simulation_4",
    description: "Starfield_Simulation #4",
    src: "Starfield_Simulation_4.jpg",
    size: 44834,
    date: "",
    year: "",
    artist: [""],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.656Z",
  },
  {
    title: "tone-logo",
    description: "Tonefilm logotyp",
    src: "tone-logo.jpg",
    size: 15809,
    date: "",
    year: "2002",
    artist: ["Hans Appelqvist"],
    thumbnail: "",
    uploaded: "2021-03-10T21:15:05.718Z",
  },
  /*Son of Clay aka Andreas Bertilsson, Live set at Rooseum Center for Contemporary Art, Malmö January 2002
  All Spec Kit, Starfield Simulation #2, Rooseum, Malmö August 2001*/
];

export default images;
