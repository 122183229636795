import React, { Component } from "react";
import styled from "styled-components";
//import posed from "react-pose";

const StyledPage = styled.div`
  .page-content {
    width: 100%;
    min-height: 100vh;
    box-sizing: border-box;
    -webkit-overflow-scrolling: touch;
  }

  @media only screen and (min-width: 1340px) {
    .page-content {
      padding: 0px 20px 40px 40px;
    }
  }

  @media only screen and (max-width: 1340px) {
    width: 100vw;
    padding-bottom: 60px;
  }

  @media only screen and (min-width: 800px) {
    .page-content {
      padding: 130px 0px;
    }
  }

  @media only screen and (max-width: 800px) {
    .page-content {
      padding: 95px 0 0 0;
    }
  }
`;

class Page extends Component {
  componentDidMount() {
    let { title } = this.props || "Komplott";
    //TODO document.title could be a utils function or use es6 string format
    document.title = title;
  }
  render() {
    //let {title} = this.props;

    return (
      <StyledPage className="page">
        <main className="page-content">{this.props.children}</main>
      </StyledPage>
    );
  }
}

Page.defaultProps = {
  name: "",
  displayTitle: true,
  displayBg: true,
  //bgImage: bgImage,
  match: {
    url: "",
  },
};

export default Page;
