import React from "react";

const Formats = props => {
  const { formats } = props;
  
  if(!formats || formats.length < 1) {
    return null
  }

  return formats ? (
    <div>
      <strong>Format</strong>
      &nbsp;
      {formats.map(format => `${format} `)}
    </div>
  ) : null;
};

export default Formats;
