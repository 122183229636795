import styled from "styled-components";

const StyledCataloguePageNav = styled.div `
  display:flex;
  justify-content:space-around;
  width: 100vw;
  margin-bottom:30px;

  button {
    line-height: 30px;
    border: none;
    background:transparent;
    font-size: .8rem;
    font-family: "Cutive Mono", monospace;
    margin: 0 10px;
  }

  button:hover {
    transform:scale(1.1);
    cursor:pointer;
    text-decoration:underline;
  }

  @media only screen and (max-width: 720px) {
    width: calc(100vw - 22px);
    
    img {
      max-width: 100vw;
      min-width: 70vw;
      margin: 0 auto;
    }

    button {
      margin: 0;
    }
  }

`;

export default StyledCataloguePageNav;
