//TODO urls
var catalogue = [
  {
    ID: 112,
    catNr: "elupre10",
    title: "Black bat",
    date: "2016-03-31",
    year: "2016",
    released: false,
    hide: false,
    artist: "Hanna Hartman",
    thumbnail: "Hanna Hartman - Black bat - front cover_thumb.png",
    cover: "Hanna Hartman - Black bat - front cover.png",
    format: ['10" vinyl'],
    url: "black-bat",
    mailorder: {
      price: 140,
      weight: "175",
      stock: "250",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    texts: [
      {
        url: "./doc/press/blackbat.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Black bat",
          time: "7:55",
        },
      ],
    },
  },
  {
    ID: 111,
    catNr: "elupre09",
    title: "Houdinikanonen",
    date: "2015-03-30",
    year: "2014",
    released: true,
    public: true,
    hide: false,
    artist: "Andreas Bertilsson",
    thumbnail: "andreas_bertilsson_-_houdinikanonen_thumb.png",
    cover: "andreas_bertilsson_-_houdinikanonen.png",
    format: ["LP"],
    stream: ["iTunes", "Spotify", "Google music", "Amazon"],
    url: "houdinikanonen",
    mailorder: {
      price: 175,
      weight: "300",
      stock: "80",
      units: "100",
      in_cart: 0,
      sold: 0,
    },
    texts: [
      {
        url: "./doc/press/houdinikanonen.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Jag trodde bordet var en väg",
          time: "2:29",
        },
        {
          nr: "2",
          title: "Handen knäcker pennan",
          time: "5:40",
        },
        {
          nr: "3",
          title: "Ingen tar dig så lätt som du själv",
          time: "4:17",
        },
        {
          nr: "4",
          title: "Houdinikanonen",
          time: "5:42",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Vi snackar tills det bryts",
          time: "4:23",
        },
        {
          nr: "2",
          title: "Lappland 1733",
          time: "8:33",
        },
        {
          nr: "3",
          title: "Vi tar igen allt till sommaren",
          time: "7:42",
        },
      ],
    },
    streaming: [
      {
        name: "Spotify",
        http_url: "https://open.spotify.com/album/7zhE7QmqZCb56VyS6PI3uX",
        service_url: "spotify:album:7zhE7QmqZCb56VyS6PI3uX",
      },
      {
        name: "iTunes",
        http_url:
          "https://itunes.apple.com/se/album/houdinikanonen/id977270465?uo=4",
      },
    ],
  },
  {
    ID: 110,
    catNr: "elupre08",
    title: "Terry/Framtid",
    date: "2014-08-22",
    year: "2014",
    released: true,
    public: true,
    artist: "Amph",
    thumbnail: "amph-cover_thumb.png",
    cover: "amph-cover.png",
    format: ["LP "],
    stream: ["iTunes", "Spotify", "Google music", "Amazon"],
    url: "terry-framtid",
    mailorder: {
      price: 175,
      weight: "300",
      stock: "80",
      units: "100",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Terry",
          time: "14:08",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Framtid",
          time: "9:45",
        },
      ],
    },
    texts: [
      {
        url: "./doc/press/terry-framtid.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],

    streaming: [
      {
        name: "Spotify",
        http_url: "http://open.spotify.com/album/3ry621a7bsQLDAPsn7h3EH",
        service_url: "spotify:album:3ry621a7bsQLDAPsn7h3EH",
      },
      {
        name: "iTunes",
        http_url:
          "https://itunes.apple.com/us/album/terry-framtid-ep/id911005994?uo=4",
      },
      {
        name: "Amazon",
        http_url: "http://www.amazon.com/Terry-Framtid-Amph/dp/B00MXX3KOU/",
      },
      {
        name: "Google",
        http_url:
          "https://play.google.com/store/music/album/Amph_Terry_Framtid?id=B6fixw2b65oghcab" +
          "imgolcnj75e",
      },
      {
        name: "Pandora",
        http_url: "http://www.rdio.com/artist/Amph/album/Terry_/_Framtid/",
      },
    ],
  },
  {
    ID: 109,
    catNr: "escudre20",
    title: "Mats Börk som vågform",
    date: "2013-12-31",
    year: "2013",
    artist: "Mats Björk",
    thumbnail: "Mats_Bjork-Mats_bjork_som_vagform_thumb.jpg",
    cover: "Mats_Bjork-Mats_bjork_som_vagform.jpg",
    format: ["CD"],
    stream: ["iTunes", "Spotify", "Google music", "Amazon"],
    url: "mats-bjork-som-vagform",
    mailorder: {
      price: 100.0,
      status: "Mailorder",
      stock: "0",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Ögonblick",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Inuti",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Ny Skärpa",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "4",
        title: "Tidens väv",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Vågen",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "6",
        title: "Timglas",
        time: "2:10",
        ISRC: "",
      },
    ],
    texts: [
      {
        url: "./doc/press/mats-bjork-som-vagform.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
  },
  {
    ID: 108,
    catNr: "escudre19",
    title: "Sjökort: fem öar",
    date: "2013-03-07",
    year: "2013",
    artist: "Madeleine Jonsson Gille",
    thumbnail: "escudre19_thumb.jpg",
    cover: "escudre19.png",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/7E68aZZRtmjmII2pubQlfd?si=aR8FSQJvTKuKUoCvKt8t6A",
        service_url: "spotify:album:7E68aZZRtmjmII2pubQlfd",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "sjokort-fem-oar",
    mailorder: {
      price: 125.0,
      status: "Mailorder",
      weight: "80",
      units: "80",
      in_cart: 90,
      sold: 10,
    },
    tracklist: [
      {
        nr: "1",
        title: "Sand",
        time: "2:10",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Träd",
        time: "7:59",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Berg",
        time: "4:29",
        ISRC: "",
      },
      {
        nr: "4",
        title: "Sten",
        time: "4:42",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Väder",
        time: "7:46",
        ISRC: "",
      },
    ],
    soundFiles: [
      {
        nr: "1",
        title: "Sand",
        link: "",
      },
    ],
    texts: [
      {
        url: "doc/press/sjokort-fem-oar.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    soundcloud: {
      link:
        "https://w.soundcloud.com/player/?url=http%3A%2F%2Fapi.soundcloud.com%2Fplaylists" +
        "%2F2507296%3Fsecret_token%3Ds-Jhcw7",
    },
    facebook: {
      link: "",
      badge: "",
    },
  },
  {
    ID: 58,
    catNr: "escudre12",
    title: "2600 Virginia Avenue",
    date: "2011-11-22",
    price: 150.0,
    year: "2011",
    artist: "Erik Mikael Karlsson",
    thumbnail: "emk_2600_av_thumb.jpg",
    cover: "emk_2600_av.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/3YVwiLhJWQDu1pqBbMBBgN?si=NsGGx3rPS_6ZfUut56vNQw",
        service_url: "spotify:album:3YVwiLhJWQDu1pqBbMBBgN",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    status: "Mailorder",
    url: "2600-Virginia-Avenue",
    ean: "7320470139295",
    discogs:
      "https://www.discogs.com/Erik-Mikael-Karlsson-2600-Virginia-Avenue/release/102641" +
      "68",
    texts: [
      {
        url: "./doc/press/2600-virginia-avenue.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    mailorder: {
      price: 150.0,
      status: "Mailorder",
      weight: "110",
      stock: "150",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "City View: As Simple As That",
        time: "7:23",
        ISRC: "",
      },
      {
        nr: "2",
        title: "All The Nights At HoJos",
        time: "5:23",
        ISRC: "",
      },
      {
        nr: "3",
        title: "2600 Virginia Avenue",
        time: "13:30",
        ISRC: "",
      },
      {
        nr: "4",
        title: "Suite 600",
        time: "7:23",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Hong Kong Confidential ",
        time: "6:31",
        ISRC: "",
      },
      {
        nr: "6",
        title: "The Friends Of Mr Ching Ling Foo ",
        time: "11:26",
        ISRC: "",
      },
      {
        nr: "7",
        title: "Oriental Doll House ",
        time: "7:49",
        ISRC: "",
      },
      {
        nr: "8",
        title: "City View: The Night Of The Seven Dragons",
        time: "3:13",
        ISRC: "",
      },
    ],
  },
  {
    ID: 107,
    catNr: "ascudre05",
    title: "Industrisemestern kan börja",
    date: "2011-07-11",
    year: "2011",
    artist: "Andreas Bertilsson",
    thumbnail: "abertilsson_industrisemesternkanborja_thumb.jpg",
    cover: "abertilsson_industrisemesternkanborja.jpg",
    format: ["digital streaming"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/520HRFPB701u3Rnh6RhtgU?si=GTvStCBOR2y_Cn5qS0C27A",
        service_url: "spotify:album:520HRFPB701u3Rnh6RhtgU",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "industrisemestern-kan-borja",
    mailorder: {
      price: 480.0,
      status: "Mailorder",
      weight: "110",
      stock: "1",
      units: "1",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Hallå Hallå ",
        time: "4:20",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Industrisemestern kan börja",
        time: "8:18",
        ISRC: "",
      },
    ],
    images: [
      {
        url: "banjoandreas.jpg",
        thumb: "banjoandreas_thumb.jpg",
        title: "",
        description: "",
        tags: [],
      },
      {
        url: "industrisemesternkanborja.jpg",
        thumb: "industrisemesternkanborja_thumb.jpg",
        title: "",
        description: "",
        tags: [],
      },
    ],
  },
  {
    ID: 106,
    catNr: "ascudre04",
    title: "Opus Operatum",
    date: "2011-06-01",
    price: 120,
    year: "2011",
    artist: "Ophir",
    thumbnail: "ophir-opusoperatum_thumb.jpg",
    cover: "ophir-opusoperatum.jpg",
    format: ["iTunes", "Spotify", "Google music", "Wimp", "Rdio", "Amazon"],
    url: "opus-operatum",
    tracklist: [
      {
        nr: "1",
        title: "496",
        time: "05:09",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Cambio",
        time: "3:59",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Nebula 7-5",
        time: "08:04",
        ISRC: "",
      },
      {
        nr: "4",
        title: "K2",
        time: "6:00",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Céleste",
        time: "3:42",
        ISRC: "",
      },
      {
        nr: "6",
        title: "Coup d’apoplexie",
        time: "4:16",
        ISRC: "",
      },
      {
        nr: "7",
        title: "Imberopolis",
        time: "6:09",
        ISRC: "",
      },
      {
        nr: "8",
        title: "Al Sarab",
        time: "12:27",
        ISRC: "",
      },
    ],
  },
  {
    ID: 103,
    catNr: "escudre16",
    title: "H ^ 2",
    date: "2011-02-22",
    year: "2011",
    artist: "Hanna Hartman",
    thumbnail: "hartman_h2_cover_thumb.jpg",
    cover: "hartman_h2_cover.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/4P1UT2mbacm3lXYE0aJ3zH?si=7xCrYuHsQL2oHzt2XjAWYg",
        service_url: "spotify:album:4P1UT2mbacm3lXYE0aJ3zH",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "h2",
    mailorder: {
      price: 150.0,
      status: "Mailorder",
      weight: "110",
      stock: "249",
      units: "1000",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Message from the Lighthouse",
        time: "15:43",
        ISRC: "",
      },
      {
        nr: "2",
        title: "NightLock",
        time: "08:23",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Circling Blue ",
        time: "08:06",
        ISRC: "",
      },
      {
        nr: "4",
        title: "BorderLines",
        time: "09:35",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Shanghai Fireflies",
        time: "13:26",
        ISRC: "",
      },
    ],
  },
  {
    ID: 83,
    catNr: "escudre15",
    title: "Själens Ö",
    date: "2011-01-24",
    price: 64.0,
    year: "2011",
    artist: "Andreas Bertilsson",
    thumbnail: "sjalens o omslag_thumb.jpg",
    cover: "sjalens o omslag.jpg",
    format: ["CD", "LP"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/0nvGKKTdZ6cCJxqedXqgOE?si=0945xuqIQImt_B58hWGmLw",
        service_url: "spotify:album:0nvGKKTdZ6cCJxqedXqgOE",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "sjalens-o",
    mailorder: {
      price: 64.0,
      weight: "20",
      stock: "",
      units: "1000",
      in_cart: 0,
      sold: 0,
    },
    texts: [
      {
        url: "./doc/press/sjalens-o.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    tracklist: [
      {
        nr: "1",
        title: "Se som dom darrar, Hedvig Svangren",
        time: "5:02",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Döda vinkeln",
        time: "08:10",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Önskan studsar",
        time: "04:24",
        ISRC: "",
      },
      {
        nr: "4",
        title: "Kniven i hjärtat",
        time: "06:04",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Själens ö",
        time: "5:25",
        ISRC: "",
      },
      {
        nr: "6",
        title: "Smula i ett hörn ",
        time: "3:46",
        ISRC: "",
      },
      {
        nr: "7",
        title: "Just nu, allt ljus på",
        time: "4:23",
        ISRC: "",
      },
      {
        nr: "8",
        title: "Kanske lättar livet greppet runt din hals nån dag",
        time: "5:51",
        ISRC: "",
      },
    ],
  },
  {
    ID: 96,
    catNr: "maginot09",
    title: "Very Friendly 2",
    date: "2010-09-01",
    year: "2010",
    artist: "Ronnie Sundin",
    thumbnail: "vf2_thumb.jpg",
    cover: "vf2.jpg",
    format: ["Comic", "Vinyl", '7"', "Magazine"],
    url: "very-friendly-2",
    ean: "7320470126738",
    mailorder: {
      price: 65.0,
      weight: "220",
      stock: "146",
      units: "150",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          artist: "Francisco López",
          title: "untitled #216 a",
          time: "4:35",
          ISRC: "",
        },
      ],
      B: [
        {
          nr: "1",
          artist: "Francisco López",
          title: "untitled #216 b",
          time: "4:15",
          ISRC: "",
        },
      ],
    },
  },
  {
    ID: 98,
    catNr: "maginot10",
    title: "Sound of the sun",
    date: "2009-12-21",
    year: "2009",
    artist: "Mats Börk",
    thumbnail: "maginot10 - Mats Bjork - Sound of the Sun_front_thumb.jpg",
    cover: "maginot10 - Mats Bjork - Sound of the Sun_front.jpg",
    format: ["CD", "Solar cell"],
    url: "sound-of-the-sun",
    /*'mailorder' : {
            'price' : 275.00,
            'weight' : '220',
            'stock' : '0',
            'units' : '24',
            'in_cart' : 0,
            'sold' : 24
        },*/
    texts: [
      {
        url: "./doc/press/sound-of-the-sun.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    tracklist: [
      {
        nr: "1",
        title: "Sound of the Sun",
      },
    ],
  },
  {
    ID: 46,
    catNr: "elupre05",
    title: "Sunspots",
    date: "2009-11-03",
    year: "2009",
    artist: "Ronnie Sundin",
    thumbnail: "elupre05-sunspots-mini.jpg",
    cover: "elupre05-sunspots.jpg",
    format: ["LP"],
    url: "sunspots",
    mailorder: {
      price: 370.0,
      weight: "220",
      stock: "48",
      units: "24",
      in_cart: 5,
      sold: 2,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Sunspots : Interference",
          time: "20:02",
          ISRC: "",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Sunspots : The Lord of Flies",
          time: "19:08",
          ISRC: "",
        },
      ],
    },
  },
  {
    ID: 85,
    catNr: "maginot05",
    title: "Very Friendly 1",
    date: "2009-04-13",
    year: "2009",
    artist: "Ronnie Sundin",
    cover: "vf1cover.jpg",
    thumbnail: "vf1cover_thumb.jpg",
    format: ["Comic", "Vinyl", '7"', "Magazine"],
    ean: "7320470110089",
    url: "very-friendly-1",
    mailorder: {
      price: 65.0,
      weight: "220",
      stock: "10",
      units: "300",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          artist: "Lasse Marhaug",
          title: "Trondheim Tapes Revisited - Part 1",
          time: "3:19",
          ISRC: "",
        },
      ],
      B: [
        {
          nr: "1",
          artist: "Lasse Marhaug",
          title: "Trondheim Tapes Revisited - Part 2",
          time: "3:15",
          ISRC: "",
        },
      ],
    },
  },
  {
    ID: 81,
    catNr: "maginot02",
    title: "Very Friendly 0",
    date: "2008-08-26",
    year: "2008",
    artist: "Ronnie Sundin",
    ean: "7320470105641",
    cover: "framsida2.jpg",
    thumbnail: "framsida2.jpg",
    format: ["Comic", "CD", "Magazine"],
    url: "very-friendly-0",
    mailorder: {
      price: 65.0,
      weight: "220",
      stock: "10",
      units: "300",
      in_cart: 0,
      sold: 0,
    },
  },
  {
    ID: 59,
    catNr: "escudre14",
    title: "Det fysiska och det psykiska",
    date: "2008-03-31",
    year: "2008",
    artist: "Andreas Bertilsson",
    cover: "escudre14.jpg",
    thumbnail: "escudre14_thumb.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/6Xk3RCTtbkBWzHX2LmMC92?si=S6osDJbOQma3lp0bDjRNOA",
        service_url: "spotify:album:6Xk3RCTtbkBWzHX2LmMC92",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "det-fysiska-och-det-psykiska",
    mailorder: {
      price: 80.0,
      weight: "120",
      stock: "100",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Se som dom darrar, Hedvig Svangren",
        time: "5:02",
        ISRC: "",
      },
      {
        nr: "2",
        title: "Döda vinkeln",
        time: "08:10",
        ISRC: "",
      },
      {
        nr: "3",
        title: "Önskan studsar",
        time: "04:24",
        ISRC: "",
      },
      {
        nr: "4",
        title: "Kniven i hjärtat",
        time: "06:04",
        ISRC: "",
      },
      {
        nr: "5",
        title: "Själens ö",
        time: "5:25",
        ISRC: "",
      },
      {
        nr: "6",
        title: "Smula i ett hörn ",
        time: "3:46",
        ISRC: "",
      },
      {
        nr: "7",
        title: "Just nu, allt ljus på",
        time: "4:23",
        ISRC: "",
      },
      {
        nr: "8",
        title: "Kanske lättar livet greppet runt din hals nån dag",
        time: "5:51",
        ISRC: "",
      },
    ],
  },
  {
    ID: 87,
    catNr: "ascari02",
    title: "Sett allt, inget förstått",
    date: "2008-03-01",
    year: "2008",
    artist: "Andreas Bertilsson",
    cover: "DSC03764.jpg",
    thumbnail: "DSC03764.jpg",
    format: ["Merchandise"],
    status: "Mailorder",
    url: "sett-allt-inget-forstatt",
    mailorder: {
      price: 25.0,
      weight: "20",
      stock: "50",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
  },
  {
    ID: 22,
    catNr: "bull19",
    title: "On Landscape Architecture",
    date: "2007-11-18",
    year: "2007",
    artist: "No Source One",
    thumbnail: "f_gillberg-on_landscape_thumb.jpg",
    cover: "f_gillberg-on_landscape.jpg",
    format: ["Mp3"],
    url: "on-landscape-architecture",
    tracklist: [
      {
        nr: "1",
        title: "Passage November (2005)",
      },
      {
        nr: "2",
        title: "Untiteld Flyby (Outdoor soundinstallation 2004)",
      },
    ],
    download: {
      link: "bull19.zip",
    },
  },
  {
    ID: 56,
    catNr: "escudre10",
    title: "The End Of A System Of Things",
    date: "2007-11-17",
    year: "2007",
    artist: "Henning Lundkvist",
    thumbnail: "henning_lundkvist_the_end_thumb.jpg",
    cover: "henning_lundkvist_the_end.jpg",
    format: ["CD"],
    url: "the-end-of-a-system-of-things",
    tracklist: [
      {
        nr: "1",
        title: "The End Of A System Of Things",
        time: "5:51",
        ISRC: "",
      },
    ],
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
  },
  {
    ID: 21,
    catNr: "bull18",
    title: "Kinaxixi",
    date: "2007-07-25",
    year: "2007",
    artist: "Anton Lennartsson",
    thumbnail: "Anton Lennartsson - kinaxixi_thumb.jpg",
    cover: "Anton Lennartsson - kinaxixi.jpg",
    format: ["Mp3"],
    url: "Kinaxixi",
    tracklist: [
      {
        nr: "1",
        title: "Exhalation",
        time: "3:18",
      },
      {
        nr: "2",
        title: "Protopterus",
        time: "2:29",
      },
      {
        nr: "3",
        title: "Incubation",
        time: "2:43",
      },
      {
        nr: "4",
        title: "Rebound",
        time: "5:40",
      },
      {
        nr: "5",
        title: "Atone",
        time: "4:00",
      },
    ],
    download: {
      link: "bull18.zip",
    },
  },
  {
    ID: 19,
    catNr: "bull16",
    title: "Namur",
    date: "2007-06-28",
    year: "2006",
    artist: "Anton Lennartsson",
    thumbnail: "Anton Lennartsson - namur_thumb.jpg",
    cover: "Anton Lennartsson - namur.jpg",
    format: ["Mp3"],
    url: "namur",
    download: {
      link: "bull16.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Perpetuum mobile",
        time: "6:03",
      },
      {
        nr: "2",
        title: "Dissolution",
        time: "2:23",
      },
      {
        nr: "3",
        title: "Progression",
        time: "4:17",
      },
      {
        nr: "4",
        title:
          '"o dieses trunkene Aug, das hier umherirrt wie wir und uns zuweilen staunend in ' +
          "eins schaut&quo",
        time: "5:41",
      },
      {
        nr: "5",
        title: "recapitulation/rose",
        time: "4:23",
      },
    ],
  },
  {
    ID: 55,
    catNr: "escudre09",
    title: "Ailanthus",
    date: "2007-04-09",
    year: "2007",
    artist: "Hanna Hartman",
    thumbnail: "hanna_hartman_-_ailanthus_thumb.jpg",
    cover: "hanna_hartman_-_ailanthus.jpg",
    url: "ailanthus",
    ean: "7320470075456",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/2WwUiVcJnLJD3dV4GUUHiw?si=T5fErO8HTU2_hmpkOFtZTA",
        service_url: "spotify:album:2WwUiVcJnLJD3dV4GUUHiw",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    mailorder: {
      price: 80.0,
      weight: "120",
      stock: "100",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "ATT FÄLLA GROVA TRÄD ÄR FÖRKNIPPAT MED RISKER",
        time: "8:35",
      },
      {
        nr: "2",
        title: "WESPEN VESPER",
        time: "9:05",
      },
      {
        nr: "3",
        title: "PLÅTMÅS",
        time: "3:02",
      },
      {
        nr: "4",
        title: "MUSIK FÖR DANSSTYCKET JAG GLÖMMER BORT",
        time: "6:35",
      },
    ],
  },
  {
    ID: 54,
    catNr: "escudre08",
    title: "Paramount",
    date: "2007-03-20",
    year: "2007",
    artist: "Andreas Bertilsson",
    thumbnail: "andreas_bertilsson_-_paramount_thumb.jpg",
    cover: "andreas_bertilsson_-_paramount.jpg",
    url: "paramount",
    ean: "7320470074237",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/4HOGv8whWLkjIJOrReOv7N?si=fn0y0V-vSG2MoCIVPHo3MQ",
        service_url: "spotify:album:4HOGv8whWLkjIJOrReOv7N",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "496",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "MOVEMENT 1 (PLAINS OF THE BUFFALO)",
        time: "9:06",
      },
      {
        nr: "2",
        title: "MOVEMENT 2 (RIDING THE BEAST) ",
        time: "10:48",
      },
      {
        nr: "3",
        title: "MOVEMENT 3 (A MOTH TO THE FLAME)",
        time: "10:04",
      },
    ],
  },
  {
    ID: 20,
    catNr: "bull17",
    title: "kommer lastat",
    date: "2007-01-17",
    year: "2007",
    artist: "Ett skepp",
    thumbnail: "ett skepp - Kommer Lastat - Cover_thumb.jpg",
    cover: "ett skepp - Kommer Lastat - Cover.jpg",
    format: ["Mp3"],
    url: "kommer-lastat",
    download: {
      link: "",
    },
    tracklist: [
      {
        nr: "1",
        title: "gräshoppan",
        time: "11:42",
      },
      {
        nr: "2",
        title: "tapiren",
        time: "7:30",
      },
      {
        nr: "3",
        title: "igelkotten",
        time: "12:41",
      },
      {
        nr: "4",
        title: "känguruungen",
        time: "13:30",
      },
      {
        nr: "5",
        title: "grävlingen",
        time: "17:42",
      },
      {
        nr: "6",
        title: "krokodilen",
        time: "1:21",
      },
    ],
  },
  {
    ID: 18,
    catNr: "bull15",
    title: "Études",
    date: "2006-10-19",
    year: "2006",
    artist: "Tsukimono",
    thumbnail: "Tsukimono_etudes.jpg",
    cover: "Tsukimono_etudes.jpg",
    format: ["Mp3"],
    url: "etudes",
    download: {
      link: "bull15.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Études I",
      },
      {
        nr: "2",
        title: "Études II",
      },
      {
        nr: "3",
        title: "Études III",
      },
      {
        nr: "4",
        title: "Études IV",
      },
      {
        nr: "5",
        title: "Études V",
      },
    ],
  },
  {
    ID: 2,
    catNr: "ascudre01",
    title: "Three Easy Pieces",
    date: "2006-08-21",
    year: "2006",
    artist: "Son of clay",
    thumbnail: "sonofclay_threeeasypieces_mini.jpg",
    cover: "sonofclay_threeeasypieces.jpg",
    format: ["Download"],
    url: "three-easy-pieces",
    download: {
      link: "ascudre01.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "We Dont Interpret (We Analyze) ",
      },
      {
        nr: "2",
        title: "Thyme, Hyssop, Thrift and Box",
      },
      {
        nr: "3",
        title: "Western Civilization Died Within Me",
      },
    ],
  },
  {
    ID: 53,
    catNr: "escudre07",
    title: "The Amateur Hermetic",
    date: "2006-03-25",
    year: "2006",
    artist: "Ronnie Sundin",
    thumbnail: "RSundin-The-Amateur_thumb.jpg",
    cover: "Ronnie-Sundin-Amateur_Hermetic-front-cover.png",
    format: ["CD"],
    url: "the-amateur-hermetic",
    ean: "7320470046159",
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "300",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "The Amateur Hermetic",
        time: "41:10",
      },
    ],
  },
  {
    ID: 52,
    catNr: "escudre06",
    title: "Longitude / Cratere",
    date: "2005-09-23",
    year: "2005",
    artist: "Hanna Hartman",
    thumbnail: "hanna_hartman_-_cratere_large_cover_thumb.jpg",
    cover: "hanna_hartman_-_cratere_large_cover.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/5335Z6eptEhNSWeEzVGgXT?si=y-62Jwf0T_y2SxDJ3sJI9A",
        service_url: "spotify:album:5335Z6eptEhNSWeEzVGgXT",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    status: "Mailorder",
    url: "longiture-cratere",
    ean: "7320470046159",
    /*'mailorder' : {
            'price' : 80.00,
            'weight' : '120',
            'stock' : '100',
            'units' : '1000',
            'in_cart' : 0,
            'sold' : 0
        },*/
    tracklist: [
      {
        nr: "1",
        title: "Longitude 013° 26",
        time: "18:00",
      },
      {
        nr: "2",
        title: "Cratere",
        time: "27:03",
      },
    ],
  },
  {
    ID: 16,
    catNr: "bull13",
    title: "Människornas Ögon",
    date: "2005-04-07",
    year: "2005",
    artist: "Hans Appelqvist",
    thumbnail: "manniskornas ogon  24_thumb.jpg",
    cover: "manniskornas ogon  24.jpg",
    format: ["Mp3", "Video"],
    url: "manniskornas-ogon",
    download: {
      link: "bull13.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Hans Appelqvist - Manniskornas ogon.avi",
      },
      {
        nr: "2",
        title: "Hans Appelqvist - Människornas ogon.mp3",
      },
    ],
  },
  {
    ID: 17,
    catNr: "bull14",
    title: "Solsting",
    date: "2005-04-07",
    year: "2005",
    artist: "Ronnie Sundin",
    thumbnail: "ronnie_sundin_-_solsting_thumb.jpg",
    cover: "ronnie_sundin_-_solsting.jpg",
    format: ["Mp3"],
    url: "solsting",
    download: {
      link: "bull14.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Solsting",
        time: "42:06",
      },
    ],
  },
  {
    ID: 51,
    catNr: "escudre05",
    title: "Busy Topic",
    date: "2005-03-01",
    year: "2005",
    artist: "All Spec Kit",
    thumbnail: "all_spec_kit_-_busy_topic_c_thumb.jpg",
    cover: "all_spec_kit_-_busy_topic_c.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/7ss7JCp0vLTgG2XMpXviub?si=fM98AwzGTJKRBZFlIWRRYg ",
        service_url: "spotify:album:7ss7JCp0vLTgG2XMpXviub",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    ean: "7320470052884",
    url: "busy-topic",
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "323",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "MY BUSY FINGERS",
        time: "4:16",
      },
      {
        nr: "2",
        title: "POLKA TIGER",
        time: "5:33",
      },
      {
        nr: "3",
        title: "GIRL BAG",
        time: "4:35",
      },
      {
        nr: "4",
        title: "YOU MAKE ME FUN",
        time: "5:32",
      },
      {
        nr: "5",
        title: "SPECK IT / SPÄCKA DET ",
        time: "3:24",
      },
      {
        nr: "6",
        title: "THE SMELL OF TECHNOLOGY",
        time: "5:15",
      },
      {
        nr: "7",
        title: "DISKOT",
        time: "3:53",
      },
      {
        nr: "8",
        title: "IN MANY STYLE",
        time: "2:40",
      },
      {
        nr: "9",
        title: "DO OR ROOD",
        time: "3:46",
      },
    ],
  },
  {
    ID: 50,
    catNr: "escudre04",
    title: "The Bird You Never Were",
    date: "2004-10-04",
    year: "2004",
    artist: "Son of Clay",
    thumbnail: "son_of_clay_the_bird_cover_you_never_weret_cover_thumb.jpg",
    cover: "son_of_clay_the_bird_cover_you_never_weret_cover.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/6GPTruwkDYkLhoUeAHUWO6?si=OEuHVmO7R7mApq6HKRMtgQ",
        service_url: "spotify:album:6GPTruwkDYkLhoUeAHUWO6",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    status: "Mailorder",
    url: "the-bird-you-never-were",
    ean: "7320470049709",
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Bring Me Water Or Bread",
        time: "5:32",
      },
      {
        nr: "2",
        title: "The Colour Scheme",
        time: "2:58",
      },
      {
        nr: "3",
        title: "Forest On Paper ",
        time: "1:43",
      },
      {
        nr: "4",
        title: "Vision Thing",
        time: "5:08",
      },
      {
        nr: "5",
        title: "Max Kristofer ",
        time: "4:33",
      },
      {
        nr: "6",
        title: "The Rook",
        time: "1:40",
      },
      {
        nr: "7",
        title: "I'Can't Make It Alone ",
        time: "5:25",
      },
      {
        nr: "8",
        title: "First Snowflakes Then Winter Fall",
        time: "5:35",
      },
      {
        nr: "9",
        title: "So Much Love I Can Take",
        time: "3:43",
      },
    ],
  },
  {
    ID: 29,
    catNr: "escudre03",
    title: "Bremort",
    date: "2004-05-14",
    year: "2004",
    artist: "Hans Appelqvist",
    thumbnail: "hans_appelqvist_bremort_cover_thumb.jpg",
    cover: "hans_appelqvist_bremort_cover.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/3Y7jROHErJpbWU9qjO5iy7?si=YpLfBAjqRRSiNmX15-YgEQ",
        service_url: "spotify:album:3Y7jROHErJpbWU9qjO5iy7",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "bremort",
    ean: "7320470046159",
    mailorder: {
      price: 60.0,
      weight: "120",
      stock: "100",
      units: "1500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Vid Storrondellen, onsdag eftermiddag",
        time: "1:35",
      },
      {
        nr: "2",
        title: "TÅGET FAR I HÖG HÖG FART",
        time: "1:21",
      },
      {
        nr: "3",
        title: 'Faye: "Hej, ursäkta, jag letar efter ett kafé."',
        time: "1:21",
      },
      {
        nr: "4",
        title: "TRE DAGARS REGN ÖVER BREMORT (DEL I)",
        time: "3:55",
      },
      {
        nr: "5",
        title: "Stadshusklockan slår tolv",
        time: "",
      },
      {
        nr: "6",
        title: "Torsdag morgon, korsningen Ringvägen Fridvägen",
        time: "",
      },
      {
        nr: "7",
        title: "5 x 5 / Samiels eftermiddag",
        time: "5:21",
      },
      {
        nr: "8",
        title: "Glacier lämnar jobbet för dagen",
        time: "1:08",
      },
      {
        nr: "9",
        title: "BREM OCH GLACIER",
        time: "3:42",
      },
      {
        nr: "10",
        title: 'BREMS BERÄTTELSE / Glacier: "Kan du inte berätta något?"',
        time: "2:34",
      },
      {
        nr: "11",
        title: "Tolvslaget, torsdag",
        time: "",
      },
      {
        nr: "12",
        title: "GODNATT BREMORT",
        time: "4:24",
      },
      {
        nr: "13",
        title: "Fredag morgon hos Brem",
        time: "1:42",
      },
      {
        nr: "14",
        title: "På Västergatan, Faye på väg till repetitionen",
        time: "",
      },
      {
        nr: "15",
        title: "MINNET AV KÄRLEK / Timas lyssnar från lägenheten bredvid",
        time: "5:16",
      },
      {
        nr: "16",
        title: "Timas och Miller möts utanför 80 Hz",
        time: "",
      },
      {
        nr: "17",
        title: "CLUB 80 Hz",
        time: "4:24",
      },
      {
        nr: "18",
        title: "TIMAS OCH MILLER / De möter hjortparet",
        time: "6:17",
      },
      {
        nr: "19",
        title: "Vid Stadshuset, klockan tolv, fredag",
        time: "",
      },
      {
        nr: "20",
        title: "TRE DAGARS REGN ÖVER BREMORT (DEL II) / Tillbaka på Cafina",
        time: "3:32",
      },
    ],
  },
  {
    ID: 95,
    catNr: "ascari03",
    title: "Map of Bremort",
    date: "2004-05-14",
    year: "2004",
    artist: "Hans Appelqvist",
    thumbnail: "Bremort_map_cover_thumb.jpg",
    cover: "Bremort_map_cover.jpg",
    format: ["Map"],
    url: "map-of-bremort",
    mailorder: {
      price: 25.0,
      weight: "10",
      stock: "30",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
  },
  {
    ID: 14,
    catNr: "bull12",
    title: "Flag",
    date: "2005-04-07",
    year: "2005",
    artist: "All Spec Kit",
    thumbnail: "All Spec Kit - FLAG01_thumb.jpg",
    cover: "All Spec Kit - FLAG01.jpg",
    format: ["Power Point"],
    url: "flag",
    texts: [
      {
        url: "./doc/press/flag.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    download: {
      link: "bull12.zip",
    },
  },
  {
    ID: 13,
    catNr: "bull10",
    title: "Kärrsilja, flockblomstriga 2",
    date: "2004-03-31",
    price: 0.0,
    year: "2004",
    artist: "Anders Dahl",
    thumbnail: "anders dahl_-_karrsilja,_flockblomstriga_2_cover_thumb.jpg",
    cover: "anders dahl_-_karrsilja,_flockblomstriga_2_cover.jpg",
    format: ["Mp3"],
    status: "Download",
    url: "karrsilja-flockblomstriga-2",
    download: {
      link: "bull10.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Metal Bowls, Electronics, Bouzouki",
      },
      {
        nr: "2",
        title: "Electronics, Piano, Guitar",
      },
      {
        nr: "3",
        title: "Sinewave, Bottles, Guitar, Harmonica",
      },
    ],
  },
  {
    ID: 14,
    catNr: "bull11",
    title: "Ballads",
    date: "2004-03-31",
    year: "2004",
    artist: "No Source One",
    thumbnail: "no_source_one_-_ballad_no3_cover_thumb.jpg",
    cover: "no_source_one_-_ballad_no3_cover.jpg",
    format: ["Mp3"],
    url: "ballads",
    download: {
      link: "bull11.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Ballad No3",
      },
    ],
  },
  {
    ID: 28,
    catNr: "escudre02",
    title: "Face Takes Shape",
    date: "2003-12-09",
    year: "2002",
    artist: "Son of Clay",
    thumbnail: "sonofclay_facetakesshape_largecover.jpg",
    cover: "sonofclay_facetakesshape_largecover.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/7qW83O96G9YjCUfo4lEd3V?si=fkkOUjTPQiKkKjpdfNXTJA",
        service_url: "spotify:album:7qW83O96G9YjCUfo4lEd3V",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "face-takes-shape",
    mailorder: {
      price: 60.0,
      weight: "10",
      stock: "30",
      units: "1000",
      in_cart: 0,
      sold: 0,
    },
    tracklist: [
      {
        nr: "1",
        title: "Face Takes Shape",
        time: "4:57",
      },
      {
        nr: "2",
        title: "Little Wheel",
        time: "5:45",
      },
      {
        nr: "3",
        title: "Bed On My Back",
        time: "3:53",
      },
      {
        nr: "4",
        title: "New Garden",
        time: "4:43",
      },
      {
        nr: "5",
        title: "NoteBook",
        time: "5:26",
      },
      {
        nr: "6",
        title: "Back Pages Of Mine ",
        time: "2:32",
      },
      {
        nr: "7",
        title: "Trapped Like A Rat In A Pack",
        time: "4:26",
      },
      {
        nr: "8",
        title: "Road Turn To Purple, You Turn To Me",
        time: "6:14",
      },
      {
        nr: "9",
        title: "Okey Tone",
        time: "3:46",
      },
      {
        nr: "10",
        title: "Two Polar Sleds",
        time: "5:47",
      },
      {
        nr: "11",
        title: "For Astrid",
        time: "4:44",
      },
    ],
  },
  {
    ID: 12,
    catNr: "bull09",
    title: "Starsleep",
    date: "2003-10-07",
    year: "2003",
    artist: "Ronnie Sundin",
    thumbnail: "ronnie_sundin_starsleep_cov_thumb.jpg",
    cover: "ronnie_sundin_starsleep_cov_thumb.jpg",
    format: ["Mp3"],
    url: "starsleep",
    download: {
      link: "bull09.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Starsleep",
      },
    ],
  },
  {
    ID: 11,
    catNr: "bull08",
    title: "Blank Tapes Live in Malmö",
    date: "2003-10-07",
    year: "2003",
    artist: "Reynols",
    thumbnail: "reynols_blank_tapes_thumb.jpg",
    cover: "reynols_blank_tapes.jpg",
    format: ["Mp3"],
    url: "blank-tapes-live-in-malmo",
    download: {
      link: "bull08.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Blank Tapes Live in Malmö",
      },
    ],
  },
  {
    ID: 10,
    catNr: "bull07",
    title: "Anton 2",
    date: "2003-07-01",
    year: "2003",
    artist: "Anton Lennartsson",
    thumbnail: "anton_lennartsson_cover_thumb.jpg",
    cover: "anton_lennartsson_cover.jpg",
    format: ["Mp3"],
    url: "anton-2",
    download: {
      link: "bull07.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Sömnen",
      },
      {
        nr: "2",
        title: "Det var högt",
      },
      {
        nr: "3",
        title: "Innocentius II",
      },
      {
        nr: "4",
        title: "Ljud från de gråa husen och de gråa dagarna",
      },
    ],
    texts: [
      {
        url: "./doc/press/anton2.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
  },
  {
    ID: 9,
    catNr: "bull06",
    title: "Eari Railroad",
    date: "2003-06-01",
    year: "2003",
    artist: "Tsukimono",
    thumbnail: "tsukimono_erie_railroad_front_thumb.jpg",
    cover: "tsukimono_erie_railroad_front.jpg",
    format: ["Mp3"],
    url: "eari-railroad",
    download: {
      link: "bull06.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Sofi is sad",
      },
      {
        nr: "2",
        title: "Baning my head against a brick wall",
      },
      {
        nr: "3",
        title: "Cut me out of all pictures",
      },
      {
        nr: "4",
        title: "Bit my leg off",
      },
      {
        nr: "5",
        title: "Tv in the backround",
      },
      {
        nr: "6",
        title: "Complex satts of platina",
      },
    ],
  },
  {
    ID: 8,
    catNr: "bull05",
    title: "Mars",
    date: "2003-05-08",
    year: "2003",
    artist: "Erik Levander",
    thumbnail: "erik-levander_mars_cover_thumb.jpg",
    cover: "erik-levander_mars_cover.jpg",
    format: ["Mp3"],
    url: "mars",
    download: {
      link: "bull05.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Imorgon för dig",
      },
      {
        nr: "2",
        title: "Faunfingrar",
      },
      {
        nr: "3",
        title: "Om denna väg",
      },
      {
        nr: "4",
        title: "Frånvaron",
      },
      {
        nr: "5",
        title: "Anglais",
      },
    ],
  },
  {
    ID: 7,
    catNr: "bull04",
    title: "Manipulations in folkk minor",
    date: "2003-04-01",
    year: "2003",
    artist: "Osso Bucco",
    thumbnail: "osso-bucco_manipulations_cover_thumb.jpg",
    cover: "osso-bucco_manipulations_cover.jpg",
    format: ["Mp3"],
    url: "manipulations-in-folkk-minor",
    download: {
      link: "bull04.zip?alt=media&token=c4b20fa0-7050-4d63-b80d-4e7c56b10a72",
    },
    tracklist: [
      {
        nr: "1",
        title: "Stomach Full of Empty",
      },
      {
        nr: "2",
        title: "A Hymn: On Sweet Recollection",
      },
      {
        nr: "3",
        title: "These Days Have Forgotten",
      },
      {
        nr: "4",
        title: "Less of Susl",
      },
      {
        nr: "5",
        title: "Fresh Linen Night",
      },
    ],
  },
  {
    ID: 5,
    catNr: "bull02",
    title: "The first three notes of the minor scale",
    date: "2003-02-01",
    year: "2003",
    artist: "Hans Appelqvist",
    thumbnail: "hansappelqvist_mollskalan_thumb.jpg",
    cover: "hansappelqvist_mollskalan.jpg",
    format: ["Mp3"],
    url: "the-first-three-notes-of-the-minor-scale",
    download: {
      link: "bull02.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Inledning",
      },
      {
        nr: "2",
        title: "Gumman",
      },
      {
        nr: "3",
        title: "Tv",
      },
      {
        nr: "4",
        title: "Uterum",
      },
      {
        nr: "5",
        title: "Trafik",
      },
    ],
  },
  {
    ID: 15,
    catNr: "bull01",
    title: "Bull 1",
    date: "2002-10-01",
    year: "2002",
    artist: "Henning Lundkvist",
    thumbnail: "",
    cover: "bull01.jpg",
    format: ["Mp3"],
    url: "bull-1",
    download: {
      link: "bull01.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "May",
      },
      {
        nr: "2",
        title: "June",
      },
      {
        nr: "3",
        title: "July",
      },
      {
        nr: "4",
        title: "August",
      },
      {
        nr: "5",
        title: "Song of Summer 2002",
      },
    ],
  },
  {
    ID: 27,
    catNr: "escudre01",
    title: "Tonefilm",
    year: "2002",
    date: "2002-06-07",
    artist: "Hans Appelqvist",
    thumbnail: "HansAppelqvist_tonefilm_cover_thumb.jpg",
    cover: "HansAppelqvist_Tonefilm.jpg",
    format: ["CD"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/3mP6wAKFfUmeQ6spDyWeGe?si=guKGGVfaSZefuishFK0pSw",
        service_url: "spotify:album:3mP6wAKFfUmeQ6spDyWeGe",
      },
      {
        name: "iTunes",
        http_url: "",
      },
      {
        name: "Amazon",
        http_url: "",
      },
      {
        name: "Google",
        http_url: "",
      },
      {
        name: "Pandora",
        http_url: "",
      },
    ],
    url: "tonefilm",
    tracklist: [
      {
        nr: "1",
        title: "...",
      },
      {
        nr: "2",
        title: "Grammofonnummer",
      },
      {
        nr: "3",
        title: "Ser Ni Lena Jag Ser Lena och Ola",
      },
      {
        nr: "3",
        title: "Bakfylleoro",
      },
      {
        nr: "3",
        title: "Frihet",
      },
      {
        nr: "3",
        title: "Bortom Haven",
      },
      {
        nr: "3",
        title: "De Förgyller Varandras Slut",
      },
      {
        nr: "3",
        title: "Ty Störst Av Allt är kärleken",
      },
    ],
    mailorder: {
      price: 150.0,
      weight: "140",
      stock: "00",
      units: "992",
      in_cart: 0,
      sold: 0,
    },
  },
  {
    ID: 45,
    catNr: "strsim12",
    title: "Starfield Simulation Series 12",
    date: "2002-05-01",
    year: "2002",
    artist: "Figurera",
    thumbnail: "strsim12_largecover_thumb.jpg",
    cover: "strsim12_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-12",
    download: {
      link: "strsim12.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "gitr",
      },
      {
        nr: "2",
        title: "opus bull",
      },
      {
        nr: "3",
        title: "13 rep",
      },
    ],
  },
  {
    ID: 44,
    catNr: "strsim11",
    title: "Starfield Simulation Series 11",
    date: "2002-05-01",
    year: "2002",
    artist: "Dag-Are Haugan",
    thumbnail: "strsim11_largecover_thumb.jpg",
    cover: "strsim11_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-11",
    download: {
      link: "strsim11.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "The throwing of that first  ",
      },
    ],
  },
  {
    ID: 43,
    catNr: "strsim10",
    title: "Starfield Simulation Series 10",
    date: "2002-04-01",
    year: "2002",
    artist: "Mathias Kristersson",
    thumbnail: "strsim10_largecover_thumb.jpg",
    cover: "strsim10_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-10",
    download: {
      link: "strsim10.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "1",
      },
      {
        nr: "2",
        title: "2",
      },
      {
        nr: "3",
        title: "3",
      },
    ],
  },
  {
    ID: 42,
    catNr: "strsim09",
    title: "Starfield Simulation Series 09",
    date: "2002-04-01",
    year: "2002",
    artist: "Tobias Tott & Max Palax",
    thumbnail: "strsim09_largecover_thumb.jpg",
    cover: "strsim09_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-9",
    download: {
      link: "strsim09.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "malmö100",
      },
      {
        nr: "2",
        title: "malmö200",
      },
      {
        nr: "3",
        title: "III(version)",
      },
      {
        nr: "4",
        title: "september1999",
      },
    ],
  },
  {
    ID: 41,
    catNr: "strsim08",
    title: "Starfield Simulation Series 08",
    date: "2002-03-01",
    year: "2002",
    artist: "Claudia Bonarelli",
    thumbnail: "strsim08_largecover_thumb.jpg",
    cover: "strsim08_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-8",
    download: {
      link: "strsim08.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "1",
      },
      {
        nr: "2",
        title: "2",
      },
      {
        nr: "3",
        title: "3",
      },
      {
        nr: "4",
        title: "4",
      },
      {
        nr: "5",
        title: "5",
      },
      {
        nr: "6",
        title: "6",
      },
    ],
  },
  {
    ID: 40,
    catNr: "strsim07",
    title: "Starfield Simulation Series 07",
    date: "2002-03-01",
    year: "2002",
    artist: "All Spec Kit",
    thumbnail: "strsim07_largecover_thumb.jpg",
    cover: "strsim07_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-7",
    download: {
      link: "strsim07.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "ett djur",
      },
      {
        nr: "2",
        title: "telefonsmörgås",
      },
      {
        nr: "3",
        title: "simple conversation aid",
      },
      {
        nr: "4",
        title: "table bord",
      },
    ],
  },
  {
    ID: 39,
    catNr: "strsim06",
    title: "Starfield Simulation Series 06",
    date: "2002-03-01",
    year: "2002",
    artist: "Smyglyssna",
    thumbnail: "strsim06_largecover_thumb.jpg",
    cover: "strsim06_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-6",
    download: {
      link: "strsim06.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "9804",
      },
      {
        nr: "2",
        title: "0006",
      },
      {
        nr: "3",
        title: "9803",
      },
      {
        nr: "4",
        title: "003",
      },
    ],
  },
  {
    ID: 38,
    catNr: "strsim05",
    title: "Starfield Simulation Series 05",
    date: "2002-02-01",
    year: "2002",
    artist: "Tickula.9",
    thumbnail: "strsim05_largecover_thumb.jpg",
    cover: "strsim05_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-5",
    download: {
      link: "strsim05.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "d(2)k",
      },
      {
        nr: "2",
        title: "hotel5(a)",
      },
      {
        nr: "3",
        title: "last_chance",
      },
      {
        nr: "4",
        title: "opportun",
      },
      {
        nr: "5",
        title: "'alaska  ",
      },
    ],
  },
  {
    ID: 37,
    catNr: "strsim04",
    title: "Starfield Simulation Series 04",
    date: "2002-01-01",
    year: "2002",
    artist: "Son of Clay",
    thumbnail: "strsim04_largeCover_thumb.jpg",
    cover: "strsim04_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-4",
    download: {
      link: "strsim04.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "1914_c",
      },
      {
        nr: "2",
        title: "11127_b",
      },
      {
        nr: "3",
        title: "11127_a",
      },
    ],
  },
  {
    ID: 36,
    catNr: "strsim03",
    title: "Starfield Simulation Series 03",
    date: "2002-01-01",
    year: "2002",
    artist: "No Source One",
    thumbnail: "strsim03_largeCover_thumb.jpg",
    cover: "strsim03_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-3",
    download: {
      link: "strsim03.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "Dalaplan-Fbg6a  ",
      },
    ],
  },
  {
    ID: 35,
    catNr: "strsim02",
    title: "Starfield Simulation Series 02",
    date: "2001-12-01",
    year: "2001",
    artist: "DN FN",
    thumbnail: "strsim02_largeCover_thumb.jpg",
    cover: "strsim02_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-2",
    download: {
      link: "strsim02.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "a1",
      },
      {
        nr: "2",
        title: "b2",
      },
      {
        nr: "3",
        title: "d1",
      },
      {
        nr: "4",
        title: "d2",
      },
      {
        nr: "5",
        title: "app.03",
      },
    ],
  },
  {
    ID: 34,
    catNr: "strsim01",
    title: "Starfield Simulation Series 01",
    date: "2001-12-01",
    price: 0.0,
    year: "2001",
    artist: "Ronnie Sundin",
    thumbnail: "strsim01_largeCover_thumb.jpg",
    cover: "strsim01_largecover.jpg",
    format: ["Mp3"],
    url: "starfield-simulation-series-1",
    download: {
      link: "strsim01.zip",
    },
    tracklist: [
      {
        nr: "1",
        title: "1",
      },
      {
        nr: "2",
        title: "2",
      },
    ],
  },
  {
    ID: 25,
    catNr: "elupre03",
    title: "Circulation",
    date: "2001-07-13",
    year: "2001",
    artist: "Twine",
    thumbnail: "twine_circulation_largecover.jpg",
    cover: "twine_circulation.jpg",
    format: ["LP"],
    url: "circulation",
    mailorder: {
      price: 110.0,
      weight: "300",
      stock: "200",
      units: "1000",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Summary",
        },
        {
          nr: "2",
          title: "Phenet",
        },
        {
          nr: "3",
          title: "Rono",
        },
        {
          nr: "4",
          title: "Low",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Voices",
        },
        {
          nr: "2",
          title: "Keys",
        },
        {
          nr: "3",
          title: "Phlitt",
        },
      ],
    },
  },
  {
    ID: 61,
    catNr: "evil01",
    title: "Starfield Simulation Live at Rooseum 010324",
    date: "2001-06-10",
    year: "2001",
    artist: "Various artists",
    thumbnail: "starfieldsimulation_liveatrooseum_largecover.jpg",
    cover: "starfieldsimulation_liveatrooseum_largecover.jpg",
    format: ["LP"],
    url: "starfield-simulation-live-at-rooseum-010324",
    mailorder: {
      price: 150.0,
      weight: "300",
      stock: "0",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          artist: "All Spec Kit",
          title: "Bet Leuty Boose",
        },
        {
          nr: "2",
          artist: "Figurera",
          title: "Here To Begin",
        },
        {
          nr: "3",
          artist: "Belavox",
          title: "Nicmoc (Excerpt)",
        },
        {
          nr: "4",
          artist: "DN FN",
          title: "App. 04",
        },
        {
          nr: "5",
          artist: "Fumca",
          title: "Excerpt",
        },
        {
          nr: "6",
          artist: "Kaputt",
          title: "Still Sexy",
        },
      ],
      B: [
        {
          nr: "7",
          artist: "Son of Clay",
          title: "Soy Falcon (Excerpt)",
        },
        {
          nr: "8",
          artist: "No Source One",
          title: "Mega Delay 2001 Pt.1",
        },
        {
          nr: "9",
          artist: "Mathias Kristersson",
          title: "A Creative..a..a..a",
        },
        {
          nr: "10",
          artist: "Tott & Palax",
          title: "Excerpt",
        },
        {
          nr: "11",
          artist: "Smyglyssna",
          title: "Non Analog Bullshit",
        },
      ],
    },
  },
  {
    ID: 60,
    catNr: "esdilar01",
    title: "de Reparera",
    date: "2000-08-10",
    year: "2000",
    artist: "Figurera",
    thumbnail: "figurera_deReparera_cover_thumb.jpg",
    cover: "figurera_deReparera_cover.jpg",
    format: ["LP"],
    url: "de-reparera",
    mailorder: {
      price: 90.0,
      weight: "300",
      stock: "0",
      units: "300",
      in_cart: 0,
      sold: 0,
    },
    stream: ["iTunes", "Spotify"],
    streaming: [
      {
        name: "Spotify",
        http_url:
          "https://open.spotify.com/album/6nNrkmNNaCnKMLsnkaq8Al?si=lDFdzr96RCmxXCHeMiV_oQ",
        service_url: "spotify:album:3mP6wAKFfUmeQ6spDyWeGe",
      },
      {
        name: "iTunes",
        http_url: "",
      },
    ],
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Elehqn",
        },
        {
          nr: "2",
          title: "Lael",
        },
        {
          nr: "3",
          title: "Inikar",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Persona",
        },
        {
          nr: "2",
          title: "Asra, asra",
        },
        {
          nr: "3",
          title: "We enell",
        },
      ],
    },
  },
  {
    ID: 15,
    catNr: "iquivi03",
    title: "Whest Side",
    date: "2000-06-01",
    year: "2000",
    artist: "Komp",
    thumbnail: "komp_whest-side.jpg",
    cover: "komp_whest-side.jpg",
    format: ["Mp3"],
    url: "whest-side",
    tracklist: [
      {
        nr: "1",
        title: "Whest side",
      },
    ],
  },
  {
    ID: 24,
    catNr: "elupre02",
    title: "Vena",
    date: "2000-06-13",
    year: "2000",
    artist: "Komp",
    thumbnail: "komp_vena_largecover1_thumb.jpg",
    cover: "komp_vena_largecover1.jpg",
    format: ["LP"],
    url: "vena",
    mailorder: {
      price: 80.0,
      weight: "300",
      stock: "0",
      units: "1000",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          title: "Whest side",
        },
        {
          nr: "2",
          title: "Lif",
        },
        {
          nr: "3",
          title: "Somebody to share",
        },
        {
          nr: "4",
          title: "171 21 Collins ave",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Mastermind",
        },
        {
          nr: "2",
          title: "Her dear love",
        },
        {
          nr: "3",
          title: "Phlic",
        },
        {
          nr: "4",
          title: "Driftwood",
        },
      ],
    },
  },
  {
    ID: 23,
    catNr: "elupre01",
    title: "Figurera",
    date: "1999-08-15",
    year: "1999",
    artist: "Figurera",
    thumbnail: "FigureraCover_thumb.jpg",
    cover: "FigureraCover.jpg",
    format: ["LP"],
    url: "figurera",
    texts: [
      {
        url: "./doc/press/figurera.md",
        title: "",
        tags: ["pressrelease"],
      },
    ],
    mailorder: {
      price: 110.0,
      weight: "300",
      stock: "0",
      units: "500",
      in_cart: 0,
      sold: 0,
    },
    tracklist: {
      A: [
        {
          nr: "1",
          title: "xivrig('Dds#KMe)",
        },
        {
          nr: "2",
          title: "MZw",
        },
        {
          nr: "3",
          title: "LSp/>0",
        },
        {
          nr: "4",
          title: "Sida (; B' ☐ ' )",
        },
        {
          nr: "5",
          title: "?nd#2kj3",
        },
      ],
      B: [
        {
          nr: "1",
          title: "Eddka",
        },
        {
          nr: "2",
          title: "u☐9",
        },
        {
          nr: "3",
          title: ", Ab ☐",
        },
        {
          nr: "4",
          title: "nvnis (ibba)",
        },
      ],
    },
    images: [
      {
        url: "bilagor/bild/figurera_01.jpg",
        thumb: "bilagor/bild/figurera_01_thumb.jpg",
        title: "",
        description: "",
        tags: [],
      },
    ],
  },
];

export default catalogue;

export function getCatalogueItem(title) {
  for (var i = 0; i < catalogue.length; i++) {
    if (catalogue[i].url === title) return catalogue[i];
  }
}

export function getNextCatalogueItem(ID) {
  for (var i = 0; i < catalogue.length; i++) {
    if (catalogue[i].ID === ID) {
      let endOfCatalogue = i + 1 > catalogue.length;
      return endOfCatalogue ? catalogue[0] : catalogue[i + 1];
    }
  }
}

export function getPreviousCatalogueItem(ID) {
  for (var i = 0; i < catalogue.length; i++) {
    if (catalogue[i].ID === ID) {
      let startOfCatalogue = i - 1 <= 0;

      return startOfCatalogue
        ? catalogue[catalogue.length - 1]
        : catalogue[i - 1];
    }
  }
}

export function getRandomCatalogueItem() {
  return catalogue[Math.floor(Math.random() * catalogue.length)];
}

export function firstEntry(ID) {
  return catalogue[0].ID === ID;
}

export function lastEntry(ID) {
  return catalogue[catalogue.length - 1].ID === ID;
}

/*
exports.Items = function () {
    return catalogue;
}



exports.Artists = function () {
    var artists = [];
    for (var i = 0; i < catalogue.length; i++) {
        //if(catalogue[i].url == url) return catalogue[i];
    }
    return artists;
}

exports.Formats = function () {
    var formats = [];
    for (var i = 0; i < catalogue.length; i++) {
        //if(catalogue[i].url == url) return catalogue[i];
    }
    return formats;
}
*/
