import styled from "styled-components";
import * as theme from "../../style";

const StyledNavigationList = styled.nav`
  border-bottom: 2px solid #000;

  .selected {
    background: ${theme.color_4};
    color: ${theme.color_2};
    padding: 10px 10px 5px 10px;
  }

  li {
    font-size: 1rem;
    margin-right: 20px;
    font-family: "Inconsolata", monospace;
    text-decoration: none;
  }

  a {
    text-decoration: none;
    color: #000;
  }

  a:hover {
    text-decoration: underline;
  }

  ul {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 30px;
    margin: 0;
    padding: 0;
  }

  @media only screen and (max-width: 800px) {
  }
`;

export default StyledNavigationList;
