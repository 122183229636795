import React from 'react';
import ListItem from './ListItem';
import styled from 'styled-components';

const List = (props) => {

  const classes = props.className
    ? props.className
    : {};

  return (
    <ul className={`${props.className} ${classes}`}>{props.children}</ul>
  );
}

List.defaultProps = {
  name: ''
};

export {ListItem};

//export default List;

export default styled(List)`
  list-style: none;
  padding: 0;
  margin: 0 0 0px 0;
`;
